import { useLocation } from '@remix-run/react'
import { useEffect } from 'react'
import { isProduction } from '#app/utils/is-production'
let pixelInitialized = false

const useMetaPixel = () => {
	const location = useLocation()

	useEffect(() => {
		if (typeof window !== 'undefined' && isProduction()) {
			if (!pixelInitialized) {
				// Initialize the Facebook Pixel
				;(function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
					if (f.fbq) return
					n = f.fbq = function () {
						n.callMethod
							? // eslint-disable-next-line prefer-spread
							  n.callMethod.apply(n, arguments)
							: n.queue.push(arguments)
					}
					if (!f._fbq) f._fbq = n
					n.push = n
					n.loaded = true
					n.version = '2.0'
					n.queue = []
					t = b.createElement(e)
					t.async = true
					t.src = v
					s = b.getElementsByTagName(e)[0]
					s.parentNode.insertBefore(t, s)
				})(
					window,
					document,
					'script',
					'https://connect.facebook.net/en_US/fbevents.js',
				)

				window.fbq('init', '198679609890064')
				pixelInitialized = true // Ensure pixel is initialized only once
			}

			// Always fire PageView on every route change
			window.fbq('track', 'PageView')
		}
	}, [location.pathname, location.search]) // Track route changes and query param changes
}

export default useMetaPixel
